import styled from "styled-components";

export const ContactSection = styled.section`
  padding: 30px 0;
`;

export const TextSecttion = styled.section`
  text-align: right;
  padding: 50px 0;
  font-size: 25px;
  font-weight: 500;
`;

export const NameForm = styled.input`
  width: 100%;
  background-color: #f5f5f5;
  border: none;
  padding: 10px;
  border-radius: 10px;
  border: 1px #ccc;
  margin-bottom: 20px;
`;

export const Email = styled.input``;
export const Subject = styled.input`
  width: 49%;
  background-color: #f5f5f5;
  border: none;
  padding: 10px;
  border-radius: 10px;
  border: 1px #ccc;
  display: inline;
`;

export const Type = styled.input`
  width: 100%;
  background-color: #f5f5f5;
  border: none;
  padding: 10px;
  border-radius: 10px;
  border: 1px #ccc;
  margin-bottom: 20px;
`;
