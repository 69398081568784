import React from "react";
import "./pageMain.css";

function PageMain1() {
  return (
    <>
      <div className="hero-image">
        <div className="hero-text">
          <h1 className="heroText">Ayman Electroinc CO</h1>
          <p className="heroP fs-4">أفضل خدمة أفضل جودة أفضل اسعار</p>
        </div>
      </div>
    </>
  );
}

export default PageMain1;
