import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./browsermenu.css";

import axios from "axios";

function BrowserMenu() {
  const [upsData, setUpsData] = useState([]);

  useEffect(() => {
    getUps();
  }, []);

  const getAttor = () => {
    axios
      .get("https://api.npoint.io/9c45523860cf7882a350")
      .then((res) => setUpsData(res.data.Alootor))
      .catch((err) => console.log(err));
  };

  const getUps = () => {
    axios
      .get("https://api.npoint.io/9c45523860cf7882a350")
      .then((res) => setUpsData(res.data.UPS))
      .catch((err) => console.log(err));
  };

  const products = upsData.map((product) => {
    return (
      <div key={product.id} className="product-card ">
        <div className="product-tumb">
          <img src={product.img} alt="" />
        </div>
        <div className="product-details">
          <span className="product-catagory">{product.name}</span>
          <h4>
            <span>{product.model}</span>
          </h4>
          <p>{product.Description}</p>
          <div className="product-bottom-details">
            <div className="product-price">
              <small>{product.Descount}</small>
              {product.Price}$
            </div>
            <div className="product-links">
              <span>
                <i className="fa fa-heart"></i>
              </span>
              <span>
                <i className="fa fa-shopping-cart"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="browserMenu" id="products">
      <Container dir="rtl">
        <div className="titlesection text-center">
          <h3 className=" fw-light fs-1">تصفح منتجاتنا</h3>
          <span
            dir="rtl"
            className="pt-5 pb-5 d-inline-block w-50 text-black-50 fs-5"
          >
            اجهزة UPS واجهزة العاكسات بمختلف الماركات العالمية واجهزة العطور
            المستوردة خصيصًا النا
          </span>

          <ul className="d-flex list-unstyled  align-items-center justify-content-center mb-0 text-center">
            <li onClick={() => getAttor()} className=" me-4 border ">
              اجهزة العطور
            </li>
            <li onClick={() => getUps()} className=" me-4 border active  ">
              عاكسات و UPS{" "}
            </li>
            {/* <li className=" me-4 border">Electronics</li> */}
          </ul>
        </div>

        <div className="p-5">
          <Row className=" d-flex justify-content-center align-content-center">
            {/* <Col className=" mb-4" lg={6}>
              <div className="d-flex  ps-4 pe-4 pt-4 pb-4 cards flex-column flex-lg-row align-items-center align-items-lg-stretch justify-content-center justify-content-lg-start">
                <div>
                  <img className="p-3" src={burgerImage} alt="" />
                </div>
                <div className=" d-flex flex-column infoContainer ps-4">
                  <div className="d-flex justify-content-between">
                    <span className=" fw-semibold">Burger Dreams</span>
                    <span className="price">$ 9.20 USD</span>
                  </div>
                  <span className="pt-3 pb-2 text-black-50 descr">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </span>
                  <div>
                    <button
                      className=" w-25 p-2 p-lg-1 btn btn-primary"
                      type=""
                    >
                      Buy
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col className=" mb-4" lg={6}>
              <div className="d-flex  ps-4 pe-4 pt-4 pb-4 cards flex-column flex-lg-row align-items-center align-items-lg-stretch justify-content-center justify-content-lg-start">
                <div>
                  <img className="p-3" src={burgerImage} alt="" />
                </div>
                <div className=" d-flex flex-column infoContainer ps-4">
                  <div className="d-flex justify-content-between">
                    <span className=" fw-semibold">Burger Dreams</span>
                    <span className="price">$ 9.20 USD</span>
                  </div>
                  <span className="pt-3 pb-2 text-black-50 descr">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </span>
                  <div>
                    <button
                      className=" w-25 p-2 p-lg-1 btn btn-primary"
                      type=""
                    >
                      Buy
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col className=" mb-4" lg={6}>
              <div className="d-flex  ps-4 pe-4 pt-4 pb-4 cards flex-column flex-lg-row align-items-center align-items-lg-stretch justify-content-center justify-content-lg-start">
                <div>
                  <img className="p-3" src={burgerImage} alt="" />
                </div>
                <div className=" d-flex flex-column infoContainer ps-4">
                  <div className="d-flex justify-content-between">
                    <span className=" fw-semibold">Burger Dreams</span>
                    <span className="price">$ 9.20 USD</span>
                  </div>
                  <span className="pt-3 pb-2 text-black-50 descr">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </span>
                  <div>
                    <button
                      className=" w-25 p-2 p-lg-1 btn btn-primary"
                      type=""
                    >
                      Buy
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col className=" mb-4" lg={6}>
              <div className="d-flex  ps-4 pe-4 pt-4 pb-4 cards flex-column flex-lg-row align-items-center align-items-lg-stretch justify-content-center justify-content-lg-start">
                <div>
                  <img className="p-3" src={burgerImage} alt="" />
                </div>
                <div className=" d-flex flex-column infoContainer ps-4">
                  <div className="d-flex justify-content-between">
                    <span className=" fw-semibold">Burger Dreams</span>
                    <span className="price">$ 9.20 USD</span>
                  </div>
                  <span className="pt-3 pb-2 text-black-50 descr">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </span>
                  <div>
                    <button
                      className=" w-25 p-2 p-lg-1 btn btn-primary"
                      type=""
                    >
                      Buy
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col className=" mb-4" lg={6}>
              <div className="d-flex  ps-4 pe-4 pt-4 pb-4 cards flex-column flex-lg-row align-items-center align-items-lg-stretch justify-content-center justify-content-lg-start">
                <div>
                  <img className="p-3" src={burgerImage} alt="" />
                </div>
                <div className=" d-flex flex-column infoContainer ps-4">
                  <div className="d-flex justify-content-between">
                    <span className=" fw-semibold">Burger Dreams</span>
                    <span className="price">$ 9.20 USD</span>
                  </div>
                  <span className="pt-3 pb-2 text-black-50 descr">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </span>
                  <div>
                    <button
                      className=" w-25 p-2 p-lg-1 btn btn-primary"
                      type=""
                    >
                      Buy
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col className=" mb-4" lg={6}>
              <div className="d-flex  ps-4 pe-4 pt-4 pb-4 cards flex-column flex-lg-row align-items-center align-items-lg-stretch justify-content-center justify-content-lg-start">
                <div>
                  <img className="p-3" src={burgerImage} alt="" />
                </div>
                <div className=" d-flex flex-column infoContainer ps-4">
                  <div className="d-flex justify-content-between">
                    <span className=" fw-semibold">Burger Dreams</span>
                    <span className="price">$ 9.20 USD</span>
                  </div>
                  <span className="pt-3 pb-2 text-black-50 descr">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </span>
                  <div>
                    <button
                      className=" w-25 p-2 p-lg-1 btn btn-primary"
                      type=""
                    >
                      Buy
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col className=" mb-4" lg={6}>
              <div className="d-flex  ps-4 pe-4 pt-4 pb-4 cards flex-column flex-lg-row align-items-center align-items-lg-stretch justify-content-center justify-content-lg-start">
                <div>
                  <img className="p-3" src={burgerImage} alt="" />
                </div>
                <div className=" d-flex flex-column infoContainer ps-4">
                  <div className="d-flex justify-content-between">
                    <span className=" fw-semibold">Burger Dreams</span>
                    <span className="price">$ 9.20 USD</span>
                  </div>
                  <span className="pt-3 pb-2 text-black-50 descr">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </span>
                  <div>
                    <button
                      className=" w-25 p-2 p-lg-1 btn btn-primary"
                      type=""
                    >
                      Buy
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col className=" mb-4" lg={6}>
              <div className="d-flex  ps-4 pe-4 pt-4 pb-4 cards flex-column flex-lg-row align-items-center align-items-lg-stretch justify-content-center justify-content-lg-start">
                <div>
                  <img className="p-3" src={burgerImage} alt="" />
                </div>
                <div className=" d-flex flex-column infoContainer ps-4">
                  <div className="d-flex justify-content-between">
                    <span className=" fw-semibold">Burger Dreams</span>
                    <span className="price">$ 9.20 USD</span>
                  </div>
                  <span className="pt-3 pb-2 text-black-50 descr">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </span>
                  <div>
                    <button
                      className=" w-25 p-2 p-lg-1 btn btn-primary"
                      type=""
                    >
                      Buy
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col className=" mb-4" lg={6}>
              <div className="d-flex  ps-4 pe-4 pt-4 pb-4 cards flex-column flex-lg-row align-items-center align-items-lg-stretch justify-content-center justify-content-lg-start">
                <div>
                  <img className="p-3" src={burgerImage} alt="" />
                </div>
                <div className=" d-flex flex-column infoContainer ps-4">
                  <div className="d-flex justify-content-between">
                    <span className=" fw-semibold">Burger Dreams</span>
                    <span className="price">$ 9.20 USD</span>
                  </div>
                  <span className="pt-3 pb-2 text-black-50 descr">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </span>
                  <div>
                    <button
                      className=" w-25 p-2 p-lg-1 btn btn-primary"
                      type=""
                    >
                      Buy
                    </button>
                  </div>
                </div>
              </div>
            </Col> */}
            <Col>{products}</Col>
          </Row>
          <div className="text-center pt-5">
            {/* <button className="btn btn-primary p-2" type="submit">
              See Full Menu
            </button> */}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default BrowserMenu;
