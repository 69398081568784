import React, { useContext } from "react";
import "./landing.css";
import { Col, Container, Row } from "react-bootstrap";
import sectionIMage from "../../Images/IMAGE.png";
import { DataPageContex } from "../../MainContetn";
function Landing() {
  const first = useContext(DataPageContex);
  return (
    <div dir="rtl" className="landing">
      <Container>
        <Row className="flex-wrap" key={first.foodLanding.id}>
          <Col
            className="d-flex flex-column justify-content-center align-content-center"
            lg="6"
            xs={12}
            key={1}
          >
            <div className="text-section pt-5 pb-5">
              <h3 className="">{first.foodLanding.headText}</h3>
              <p className="text-black-50">{first.foodLanding.suptext}</p>
            </div>
            <div className="order">
              <a
                className="btn btn-primary pt-2 pb-2 ps-4 pe-4"
                style={{ background: "#FE904D", border: "none" }}
                href="#contact"
              >
                احجز طلبك الان
              </a>
              {/* <div className="qute pt-4">
                <i className="fa-solid fa-star fs-2"></i>
                <span className="fw-semibold ">Trustpilot</span>
                <p className="pt-1 w-100">
                  <span className="rating">4.8 out of 5 </span>
                  based on 2000+ reviews
                </p>
              </div> */}
            </div>
          </Col>
          <Col className="pt-5" lg="6" xs={12}>
            <img className="pt-5 w-100" src={sectionIMage} alt="" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Landing;
