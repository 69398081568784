import { React, useState } from "react";
import { Container } from "react-bootstrap";

import emailjs from "@emailjs/browser";

import MyVerticallyCenteredModal from "../Bmenu/Call/Modal";
import {
  NameForm,
  TextSecttion,
  Email,
  Subject,
  Type,
  ContactSection,
} from "../Bmenu/Call/ContactStyle";

const mapLink =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3213.4608773178156!2d43.1662005!3d36.349613999999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4007954436988ed7%3A0xceb793aeddfebac1!2sAyman%20Electroinc!5e0!3m2!1sen!2siq!4v1664893664004!5m2!1sen!2siq";
function GoodSupport() {
  const [first, setdata] = useState({
    name: "",
    phone: "",
    subject: "",
    email: "",
    message: "",
  });
  const [modalShow, setModalShow] = useState(false);

  const style = {
    width: "100%",
    height: "700px",
    overflowX: "hidden",
    overflowY: "hidden",
    paddingBottom: "60px",
    borderRadius: "15px",
  };

  const sendmessage = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_qe2xtra",
        "template_ric5ywu",
        e.target,
        "ZBAlUGLnuSZOmF9T6"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    setdata({
      name: "",
      phone: "",
      message: "",
      subject: "",
      email: "",
    });
    setModalShow(true);
  };
  console.log(first);
  return (
    <ContactSection id="contact">
      <Container>
        <TextSecttion>
          <h3 className="contactUS">تواصل معنا </h3>
          <p>موقعنا على خرائط كوكل او تواصل معنا عبر الحقول الاتية</p>
        </TextSecttion>
        <div>
          <iframe style={style} src={mapLink} title="ayman Electroinc" />
        </div>
        <form onSubmit={sendmessage}>
          <NameForm
            onChange={(event) =>
              setdata({
                name: event.target.value,
              })
            }
            type="text"
            name="name"
            placeholder="الاسم"
            value={first.name}
            className="textARAB"
          />
          <Email
            className="email textARAB"
            onChange={(event) =>
              setdata({
                email: event.target.value,
              })
            }
            type="email"
            name="email"
            placeholder="الايميل"
            value={first.email}
          />
          <Subject
            onChange={(event) =>
              setdata({
                subject: event.target.value,
              })
            }
            type="text"
            name="subject"
            placeholder="الموضوع"
            value={first.subject}
            className="textARAB"
          />
          <Type
            onChange={(event) =>
              setdata({
                phone: event.target.value,
              })
            }
            type="tel"
            name="Number"
            placeholder="الرقم"
            value={first.phone}
            className="textARAB"
            required
            onInvalid={(F) =>
              F.target.setCustomValidity("الرجاء ادخال رقم هاتفك بشكل كامل")
            }
            onInput={(F) => F.target.setCustomValidity("")}
          />

          {/*    {first.phone.length > 4 || first.phone.length === 11 ? (
            <small className="text-danger p-1 showWarning">
              الرجاء ادخال رقم هاتفك بشكل كامل{" "}
            </small>
          ) : (
            ""
          )} */}
          <input
            onChange={(event) =>
              setdata({
                message: event.target.value,
              })
            }
            className="TextArea textARAB"
            name="des"
            placeholder="رسالتك"
            type="text"
            value={first.message}
          />
          <input className="send textArab" type="submit" value="أرسل" />
        </form>
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </Container>
    </ContactSection>
  );
}

export default GoodSupport;
