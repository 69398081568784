import React from "react";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import Modal from "react-bootstrap/Modal";

function MyVerticallyCenteredModal(props) {
  return (
    <Container>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <svg
            className="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
          <p className="Done"> تم ارسال رسالتك بناجح أنتظر ردنا قريبً</p>
        </Modal.Body>
        <Button onClick={props.onHide}>Close</Button>
      </Modal>
    </Container>
  );
}
export default MyVerticallyCenteredModal;
