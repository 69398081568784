import React from "react";
import BrowserMenu from "./Components/Bmenu/BrowserMenu";
import Products from "./Components/homeProducts/Products";
import HowItWorks from "./Components/HowItworks/HowItWorks";

import NavBar from "./Components/NavBar/NavBar";
import Online from "./Components/OnlineShop/online";
import Call from "./Components/Bmenu/Call/Call";
import GoodSupport from "./Components/GoodSupport/GoodSupport";
import Footer from "./Components/Footer/Footer";
import Courcel from "./Components/Landing/Courcel";
import OurClients from "./Components/Partnership/OurClients";
export const DataPageContex = React.createContext();
function MainContetn() {
  const pageData = {
    foodLanding: {
      id: 1,
      headText: [
        "اختيارك راح يكون صحيح افضل ",
        <span> منتجات الى باب شركتك او بيتك </span>,
      ],
      suptext: `عدنا وبس راح تلكة اجهزة الكهربائيات والعاكسات بأفضل الأسعار ونضملك احسن تجربة وخدمة وبأسعار تنافسية مختلفة عن الجميع`,
      image: "../Images/IMAGE.png",
    },
  };

  return (
    <DataPageContex.Provider value={pageData}>
      <NavBar />
      <Courcel />
      <Products />
      <HowItWorks />
      <BrowserMenu />
      <Online />
      <Call />
      <OurClients />
      <GoodSupport />
      <Footer />
    </DataPageContex.Provider>
  );
}

export default MainContetn;
