import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./howworks.css";
import image1 from "../../Images/Electrician.gif";
import image2 from "../../Images/Electrician2.gif";
import image3 from "../../Images/Electrician3.gif";
function HowItWorks() {
  return (
    <div className="howitworks">
      <Container>
        <h3 className="text-center pb-5 text-uppercase fw-bold fs-2">
          خدماتنا وشلون نشتغل
        </h3>
        <div className="productsCards d-flex justify-content-center align-items-center">
          <Row>
            <Col lg={3} className="text-center">
              <img className=" w-100" src={image3} alt="" />
              <p>خدمة الصيانة الموقعية</p>
              <span className=" text-black-50">
                وين ماجنت لاتشيل هم نقل الاجهزة احنا وين ماتكون نجيك مع كادر
                الصيانة المميز
              </span>
            </Col>
            <hr className=" d-none d-lg-inline-block mt-auto mb-auto"></hr>
            <Col lg={3} className="text-center">
              <img className=" w-100" src={image1} alt="" />

              <p dir="rtl">احنا نقدملك تنصيب العاكسات واجهزة UPS</p>
              <span className=" text-black-50">
                تنصيب بدقة واحترافية وين ماجنت نوفرها الك لشركتك لبيتك او مختبرك
                راح نكون وياك
              </span>
            </Col>
            <hr className=" d-none d-lg-inline-block mt-auto mb-auto "></hr>
            <Col lg={3} className="text-center">
              <img src={image2} alt="" className=" w-100" />
              <p>خدمة الصيانة المستمرة</p>
              <span className=" text-black-50">
                لاتتخوف من نقص قطع الغيار او صيانة خدماتنا مستمرة حتى بعد الشراء
                ودعمنا هم مستمر
              </span>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default HowItWorks;
