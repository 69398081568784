import "./App.css";
import MainContetn from "./MainContetn";

function App() {
  return (
    <div>
      <MainContetn />
    </div>
  );
}

export default App;
