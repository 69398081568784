import styled from "styled-components";

export const ClientsSeaction = styled.section`
  padding: 50px 0;
  opacity: 1;
`;

export const ImageCard = styled.img`
  width: 25%;
  // filter: grayscale(90%);
`;
