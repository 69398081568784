import React from "react";
import "./online.css";
import { Col, Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import sectionIMage from "../../Images/shc.png";
function Online() {
  return (
    <Container>
      <Row className="flex-wrap">
        <Col className="pt-5" lg="6" xs={12}>
          <img className=" w-100" src={sectionIMage} alt="" />
        </Col>
        <Col
          className="pt-5 d-flex flex-column justify-content-center align-content-center"
          lg="6"
          xs={12}
        >
          <div dir="rtl" className="text-section pt-5 pb-5">
            <h3 className="pt-5" style={{ color: "#FE904D" }}>
              املي الحقول الي بل اسفل ولاتتردد اذا جان عندك اي استفسار او حجز
              موعد دائمًا احنا مستعدين
            </h3>
            <p className="text-black-50">
              فريقنا مستمر بتقديم الخدمات والرد على أسئلتكم واستفساراتكم لاتتردد
              واحجز موعدك الأن
            </p>
          </div>
          <div dir="rtl" className="order">
            <a
              className="btn btn-primary pt-2 pb-2 ps-4 pe-4"
              style={{ background: "#FE904D", border: "none" }}
              href="#contact"
            >
              احجز طلبك الان
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Online;
