import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import shopImage from "../../../Images/goodiamge1.png";
function Call() {
  return (
    <div className="call" id="call">
      <Container>
        <Row className="flex-wrap">
          <Col
            className="pt-5 d-flex flex-column justify-content-center align-content-center"
            lg="6"
            xs={12}
          >
            <div dir="rtl" className="text-section pt-5 pb-5">
              <h3 style={{ color: "#FE904D" }} className="pt-5">
                اتصل بينا هسة بضغطة زر على الموبايل او واتساب اذا جان عندك اي
                استفسار بشكل مباشر او اي سؤال{" "}
              </h3>
              <p className="text-black-50">
                اتصل عبر ضغط احد الازرار الظاهرة يسار الشاشة على موبايل او
                واتساب وراح نلبي كل احتياجاتك وطلباتك
              </p>
            </div>
            <div dir="rtl" className="order ">
              <a
                className="btn btn-primary pt-2 pb-2 ps-4 pe-4 callbtn"
                href="tel:9647722723332"
              >
                اتصل الان موبايل
              </a>

              <a
                className="wpbtn btn btn-primary pt-2 pb-2 ps-4 pe-4 "
                href="https://wa.me/+9647722723332"
              >
                اتصل الان واتساب
                <i className="fa-brands fa-whatsapp"></i>
              </a>
            </div>
          </Col>
          <Col className="pt-5" lg="6" xs={12}>
            <img className="pt-5 w-100" src={shopImage} alt="" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Call;
