import React from "react";
import { ClientsSeaction, ImageCard } from "./OurClientsStyle";
import { Col, Row, Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";
function OurClients() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.npoint.io/9c45523860cf7882a350")
      .then((res) => setData(res.data.clinets));
  }, []);

  const dataClaintes = data.map((ele) => {
    return (
      <ImageCard
        className={ele.id === 1 ? "ImageCardAmr" : `Defulti`}
        key={ele.id}
        src={ele.image}
        alt=""
      />
    );
  });
  return (
    <ClientsSeaction>
      <h3 className="h3ser text-center ">عملائنا المميزون</h3>
      <Container>
        <Row>
          <Col>{dataClaintes}</Col>
        </Row>
      </Container>
    </ClientsSeaction>
  );
}

export default OurClients;
