import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./footer.css";
import logo from "../../Images/Logo.png";

function Footer() {
  return (
    <div className="footer">
      <Container>
        <Row>
          <Col lg={6}>
            <div className=" text-start d-flex flex-column">
              <div>
                <img src={logo} alt="logo" className="pt-4 pb-4 logo" />
              </div>

              <div className=" text-start d-flex flex-column"></div>
              <p
                style={{ flexBasis: "5vh" }}
                className=" text-black-50 flex-grow-5"
              >
                Coded with♥️ By{" "}
                <a
                  style={{ textDecoration: "none" }}
                  href="https://vitomuhammed.netlify.app"
                >
                  VitoMuhammed
                </a>
                <br />
                for small - medium businesses.
              </p>
            </div>
          </Col>
          <Col dir="rtl" lg={6}>
            <ul className=" list-unstyled">
              {/* <h4 className="fw-light fs-5">COMPANY</h4> */}
              <li className="active">
                <a href="#home">الرئيسية</a>
              </li>
              <li>
                {" "}
                <a href="#products">منتجات</a>
              </li>
              <li>
                {" "}
                <a href="#about">الشركة</a>
              </li>
              <li>
                {" "}
                <a href="#call">اتصل بنا</a>{" "}
              </li>
              <li>
                {" "}
                <a href="#contact">تواصل معنا</a>
              </li>
            </ul>
          </Col>
        </Row>
        <div className="d-flex justify-content-between align-content-center info">
          <ul className="d-flex  list-unstyled text-center">
            <li className="border  text-black-50">
              <a href="https://www.facebook.com/Ayman.msl14">
                <i className="fa-brands fa-facebook fs-4"></i>
              </a>
            </li>
            <li className=" border text-black-50">
              <a href="https://www.instagram.com/ayman.msl14/">
                <i className="fa-brands fa-instagram fs-4"></i>
              </a>
            </li>
            <li className=" border text-black-50">
              <a href="mailto:aymanfarma20192@gmail.com">
                <i className="fa-regular fa-envelope fs-4"></i>
              </a>
            </li>
          </ul>
        </div>
        <h1 style={{ opacity: 0 }}>Ayman Electroinc CO</h1>
      </Container>
    </div>
  );
}

export default Footer;
