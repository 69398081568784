import React from "react";
import { Container, Row, Col, Navbar, Nav, NavLink } from "react-bootstrap";
import "./navstyle.css";
import logo from "../../Images/Logo.png";
import market from "../../Images/Frame.png";
function NavBar() {
  return (
    // <div className="pt-3 pb-3 ">
    //   <Container>
    //     <Row>
    //       <Col xs={6} lg={8}>
    //         <div>
    //           <img src={logo} className="logo" alt="React Bootstrap logo" />
    //         </div>
    //       </Col>

    //       <Col xs={6} lg={4} className=" d-sm-flex">
    //         <div className="d-flex flex-row-reverse flex-lg-row align-items-stretch align-items-lg-center justify-content-start  justify-content-lg-center ">
    //           <ul className="d-none d-lg-flex list-unstyled  align-items-center justify-content-center mb-0 ">
    //             <li className="active">
    //               <a href="/home">Home</a>
    //             </li>
    //             <li>
    //               {" "}
    //               <a href="#products">Products</a>
    //             </li>
    //             <li>
    //               {" "}
    //               <a href="#about">Company</a>
    //             </li>
    //             <li>
    //               {" "}
    //               <a href="#call">Call</a>{" "}
    //             </li>
    //             <li>
    //               {" "}
    //               <a href="#contact">Contact</a>
    //             </li>
    //           </ul>
    //           {/* <img src={market} alt="" width={40} height={40} /> */}
    //         </div>
    //       </Col>
    //     </Row>

    //   </Container>
    // </div>

    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="#home">
          <img
            alt="ayman electronic logo"
            src={logo}
            width="60"
            height="60"
            className="d-inline-block align-top"
          />{" "}
          {/* <span className=" text-center  d-inline-block mt-2">
            {" "}
            AYMAN ELECTROINC CO
          </span> */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse dir="rtl" id="basic-navbar-nav">
          <Nav className="ms-auto">
            <NavLink href="#home">الرئيسية</NavLink>
            <NavLink href="#products">منتجات</NavLink>
            <NavLink href="#about">الشركة</NavLink>
            <NavLink href="#call">اتصل بنا</NavLink>
            <NavLink href="#contact">تواصل معنا</NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
